@import "theme/bootstrap.scss";
// html,
// body {
//   width: 100vw;
//   min-height: 100vh;
//   display: flex;
//   justify-content: center;
//   position: relative;

//   #__next {
//     overflow-x: hidden;
//     background-color: #343a40;
//     height: 100vh;
//     width: 100vw;
//     top: 0;
//     left: 0;
//     position: relative;
//   }
// }


#app {
  //max-width: 26.56rem;
  margin: 0 auto;
}
.bg-gradient {
  @include gradient-y(#e8ebf5, #ffffff);
}
.bg-gradient-dark {
  @include gradient-directional(#2A85D6, #10469A, -90deg)
}
.rounded-top-lg {
  @include border-top-radius(1rem)
}
.mt-neg {
  margin-top: -1rem;
  &-lg {margin-top: -2rem}
}
.pb-bottom-app {
  padding-bottom: 20rem;
}
ul.list-calendar {
  list-style:none;
  padding: 0;
  white-space: nowrap;
  width: 100%;
  overflow-x: auto;
  @extend .mt-2;
  @extend .mb-4;

  li {
    display: inline-block;
    @extend .h5;
    @extend .px-1;
    @extend .mx-2;
    &.active { @extend .font-weight-bold; text-decoration: underline;}
  }
}

.ft-color-white{color: #fff;}

.flinks-iframe{width: 96%;
  height: 90vh;
  border: 0;
  margin: 0 auto;
  display: block;}

.active-card {
  @extend .border-primary;
  border-width: 2px;
  background: rgba($primary, .1);
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zindex-modal-backdrop;
  background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
}
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;

}
.upload-file {
  border: 2px dashed $primary;
  background-color: rgba($primary, .1);
  @extend .py-3;
  @extend .px-2;
  @extend .rounded;
  color: $primary;
  text-align: center;
  @extend .font-weight-bold;
}

.slider-panel {
  position: fixed;
  bottom: 0;
  left: 34% ;
  width: 26.56rem;
  height: 100%;
  z-index: $zindex-modal-backdrop;
  opacity: 0;
  display: flex;
  align-items: bottom;
  pointer-events: none;

  &.show {
    background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
    opacity: 1;
    pointer-events: all;

  }
  &-dialog {
    @extend .card;
    @extend .card-body;
    margin-top: auto;
    padding-bottom: 4rem;
    transform: translateY(100%);
    transition: all .3s ease-in-out;

  }

  &.show {
    background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
    opacity: 1;
    pointer-events: all;
    .slider-panel-dialog {
      transform: translateY(0);
    }
  }
}


@media screen and (max-width: 425px) {
 .slider-panel, .custom-modal {
   left: 0;
   width: 100%;
 } 
}

.address-search-box{
  position: relative;
}
.custom-list-style{
  background-color: #FFF;
  list-style: none;
  z-index: 999;
  font-size: 0.7rem;
  padding: 0;
  
  
  .react-loqate-list-item{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    border-bottom: 1px solid #ddd;
  }
  .react-loqate-list-item:hover{
    background-color: #ddd;
    cursor: pointer;
  }
}

.chat-box{

  position: absolute; 
  right: 1.5rem; 
  bottom: 3rem; 
  z-index: 999;

  .chat-box-inner{
    position: relative;
    min-height: 50vh;
    width: 23rem;
  }
  .chat-box-dial-button{
    position: absolute;
    right: '30px'; 
    bottom: '30px'; 
    
  }
}

.MuiPhoneNumber-flagButton{
  svg{
    max-width: 100%;
    max-height: 100%;
  }
}